.css-zddlty-MuiButtonBase-root-MuiButton-root {
  width: 80px;
  height: 20px;
  background-color: rgb(0, 0, 0, 0.23) !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: "Kanit" !important;
  border-radius: 30px !important;
}
.css-1ndpvdd-MuiTableCell-root {
  font-family: "Kanit" !important;
  font-weight: 500 !important;
  color: #545859 !important;
}
.css-1yhpg23-MuiTableCell-root {
  font-family: "Kanit" !important;
  font-weight: 300 !important;
  line-height: 2 !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-radius: 8px !important;
  box-shadow: none !important;
}
.css-1q1u3t4-MuiTableRow-root {
  border: 1px solid rgba(224, 224, 224, 1);
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #ffd100;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ffd100;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
